import { graphql } from "gatsby";
import * as React from "react";
import Layout from "~/components/Layout";

import FlexibleContent from "~/components/FlexibleContent";
import { WpPage_Page_FlexibleContent } from "~/_generated/types";
import Seo from "gatsby-plugin-wpgraphql-seo";

export default function defaultPage({ data: { wpPage, wp } }: { data: { wpPage: { page: { flexibleContent: WpPage_Page_FlexibleContent } } } }) {
	return (
		<Layout wp={wp} wpPage={wpPage}>
			<Seo post={wpPage} />
			<FlexibleContent content={wpPage?.page?.flexibleContent} />
		</Layout>
	);
}

export const pageQuery = graphql`
	query Default($id: String!) {
		wpPage(id: { eq: $id }) {
			...SEO
			...GeneratedWpPage
		}
		wp {
			...GeneratedWp
		}
	}
`;
